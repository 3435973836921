var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "admin-header",
      class: { "bg-image": _vm.background },
      style: _vm.background
        ? { backgroundImage: "url(" + _vm.background + ")" }
        : false
    },
    [
      _c("div", { staticClass: "container p-4 sm-down:text-center" }, [
        _c("h1", { staticClass: "mb-0 d-block", style: { fontSize: "3em" } }, [
          _vm._v(_vm._s(_vm.title))
        ]),
        _vm._v(" "),
        _c(
          "div",
          { class: { row: _vm.row }, staticStyle: { "line-height": "1.2" } },
          [_vm._t("default")],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }