<template>
  <section
    class="admin-header"
    :class="{ 'bg-image': background }"
    :style="background ? { backgroundImage: 'url(' + background + ')' } : false"
  >
    <div class="container p-4 sm-down:text-center">
      <h1 class="mb-0 d-block" :style="{ fontSize: '3em' }">{{ title }}</h1>
      <div style="line-height: 1.2;" :class="{ row: row }">
        <slot></slot>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    title: [String, Number],
    background: String,
    text: [String, Number],
    row: [Boolean]
  },
  data() {
    return {};
  }
};
</script>
